<template>
  <div class="activity-details">
    <div class="activity-header">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/enterprise/activity_outline/' }"
          >线下活动</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ details.activityName }}详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="breads">
        <div class="br-left">
          <div class="imgs">
            <img :src="details.imgUrl" class="err_image" />
          </div>
          <div class="words">
            <h2 class="line_clamp2">{{ details.activityName }}</h2>
            <h3>{{ details.personCount }}人已报名</h3>
            <div>
              <p>
                活动时间：
                <span
                  >{{ details.activityStartTime }} 至
                  {{ details.activityEndTime }}</span
                >
              </p>
              <p>
                活动地点：
                <span
                  >{{ details.activityArea }}{{ details.activityAddress }}</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="br-right relative">
          <div class="share">
            <span class="name">分享到 <i class="el-icon-arrow-down"></i></span>
            <Share
              :url="shareUrl"
              :title="details.activityName"
              :desc="'由' + details.departName + '发布'"
            ></Share>
          </div>
          <div class="buttons">
            <el-button @click="collection">
              <img
                :src="
                  details.collect
                    ? require('@/static/public/collected.png')
                    : require('@/static/public/collect.png')
                "
                alt
                class="img inline_block"
              />
              {{ details.collect ? "已收藏" : "收藏" }}
            </el-button>
            <el-button
              :type="details.apply ? 'info' : 'primary'"
              :disabled="details.apply"
              @click="enlistApply(details.id)"
              >{{ details.apply ? "已报名" : "我要报名" }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main-left">
        <div class="left-top">
          <h2>活动详情</h2>
          <div class="left-bottom" v-html="details.activityIntroduce"></div>
        </div>
      </div>
      <div class="main-right">
        <div class="right-top marT30">
          <div v-if="details.type" class="imgs">
            <img :src="details.type" alt :title="details.departName" />
          </div>

          <div v-else class="imgs">
            <img src="~static/log_new.png" alt />
          </div>
          <h3 class="marT15 line_clamp1">{{ details.departName }}</h3>
          <p
            class="content"
            v-html="details.departdescription"
            v-if="details.departdescription"
          ></p>
          <p class="content" v-else>暂无机构介绍</p>
          <div class="connect">
            <p v-if="details.linkMan">
              联系方式：
              <span>{{ details.linkMan }}</span>
            </p>
            <p v-else>
              联系方式：
              <span>暂无</span>
            </p>
            <p v-if="details.linkEmail">
              联系邮箱：
              <span>{{ details.linkEmail }}</span>
            </p>
            <p v-else>
              联系邮箱：
              <span>暂无</span>
            </p>
          </div>
        </div>
        <div class="ad relative" v-for="(n, i) in advList" :key="i">
          <a :href="n.picUrl" :title="n.adName" class="imgAd">
            <img :src="n.picSrc" alt :title="n.adName" />
          </a>
        </div>
      </div>
    </div>
    <el-dialog
      title="线下活动"
      :visible.sync="dialogVisibles"
      width="480px"
      :before-close="handleClose"
    >
      <div class="el-dialog_content">
        <div class="title">
          <img src="@/static/public/success.png" alt class="img" />
          活动收藏成功！
        </div>
        <p>
          您可以在
          <router-link to="/" target="_blank">个人中心</router-link
          >我的收藏中查看
        </p>
      </div>
      <div slot="footer" class="dialog-footer text_c">
        <el-button type="primary" @click="dialogVisibles = false"
          >知道了</el-button
        >
      </div>
    </el-dialog>
    <login-prompt
      :goShow="isHide"
      @close="handleClose"
      :title="details.activityName + '报名'"
      text="登录成功后才可进行报名！"
    ></login-prompt>
  </div>
</template>
<script>
import Share from "components/public/Collect";
import loginPrompt from "components/public/loginPrompt";
export default {
  name: "activityDetail",
  components: { Share, loginPrompt },
  data() {
    return {
      details: [],
      advList: [],
      isHide: false,
      disabled: false,
      dialogVisibles: false,
      shareUrl: "",
    };
  },
  methods: {
    // 请求活动接口
    async outlineListDetails() {
      // let that = this;
      let res = await this.$api.outlineListDetails({
        id: this.$route.query.id,
      });
      this.shareUrl = window.location.href;
      console.log("详情页", res);
      if (res.data.success) {
        this.details = res.data.data;
        document.title =
          this.details.activityName +
          "-兰州市残疾人就业创业网络服务平台——中国残联官方残疾人求职网站";
        console.log(res, "详情");
      }
    },
    //收藏
    collection() {
      if (localStorage.getItem("userType")) {
        let login = JSON.parse(localStorage.getItem("userType")).userType;
        if (login == "1") {
          let params = {
            id: this.details.id,
            activityName: this.details.activityName,
          };
          this.$api.collectActivity(params).then((res) => {
            if (res.data.success) {
              this.details.collect = !this.details.collect;
              if (this.details.collect) {
                this.dialogVisibles = true;
              } else {
                this.$message.error("取消收藏");
              }
            }
          });
        } else if (login == "2") {
          this.$message.warning("您不是残疾人，不能点击收藏");
        } else {
          this.isHide = true;
        }
      } else {
        this.isHide = true;
      }
    },
    //报名
    enlistApply(ids) {
      if (localStorage.getItem("userType")) {
        let login = JSON.parse(localStorage.getItem("userType")).userType;
        if (login == "1") {
          let params = {
            id: this.details.id,
          };
          this.$api
            .addTopicActivity(params)
            .then((res) => {
              if (res.data.success) {
                this.details.apply = true;
                this.$message.success(res.data.msg);
                setTimeout(() => {
                  this.handleClose();
                }, 1500);
              } else {
                this.$message.warning(res.data.msg);
              }
            })
            .catch((err) => {
              this.details.apply = false;
            });
        } else if (login == "2") {
          this.$message.warning("您不是残疾人，不能报名此活动");
        } else {
          this.isHide = true;
        }
      } else {
        this.isHide = true;
      }
    },
    // 取消
    handleClose() {
      this.isHide = false;
      this.dialogVisibles = false;
    },
    //广告位
    adv() {
      this.$api
        .adApi({ classId: "40288113737032d701737032d75b0000" })
        .then((res) => {
          this.advList = res.data.data;
        });
    },
  },
  created() {
    this.outlineListDetails(this.$route.query.id);
    this.adv();
    if (this.$route.query.activityName) {
      document.title =
        this.$route.query.activityName + "-中国残联就业创业服务平台";
    }
  },
};
</script>
<style lang="less" scoped>
.activity-details {
  .el-breadcrumb {
    padding-top: 20px;
    width: 1200px;
    font-size: 14px;
    margin: auto;
    cursor: pointer;
  }
  /deep/ .el-breadcrumb__inner.is-link,
  .el-breadcrumb__inner a {
    font-weight: normal;
  }
  font-weight: bold;
  .activity-header {
    width: 100%;
    background-color: #ffffff;
    flex-direction: row;
    margin-bottom: 8px;
    padding-bottom: 20px;
    .breads {
      width: 1200px;
      display: flex;
      flex-direction: row;
      position: relative;
      margin: 30px auto;
      height: 156px;
      box-sizing: border-box;
      .br-left {
        display: flex;
        flex: 1;
        .imgs {
          margin-right: 25px;
          width: 280px;
          height: 156px;
        }
        img {
          width: 100%;
          height: 100%;
        }
        .words {
          flex: 1;
          box-sizing: border-box;
          display: flex;
          align-items: left;
          justify-content: space-between;
          flex-direction: column;
          h2 {
            font-size: 22px;
            color: #333333;
            font-weight: 500;
            line-height: 34px;
          }
          h3 {
            font-size: 18px;
            color: #00924c;
            font-weight: normal;
          }
          p {
            color: #999999;
            font-size: 14px;
            line-height: 24px;
            font-weight: normal;
          }
        }
      }
      .br-right {
        display: flex;
        align-items: center;
        .share {
          .name {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            font-weight: normal;
            color: #999999;
          }
        }
        .buttons {
          display: flex;
          margin-top: 140px;
          .el-button:nth-of-type(1) {
            width: 120px;
            height: 48px;
            border: 1px solid #00924c;
            border-radius: 5px;
            font-size: 16px;
            color: #00924c;
          }
          .el-button:nth-of-type(2) {
            width: 240px;
            height: 48px;
          }
        }
        p {
          position: absolute;
          right: 0;
          top: 40px;
          font-size: 14px;
          color: #999999;
          i {
            width: 8.5px;
            height: 5.3px;
          }
        }
      }
    }
  }
  .main {
    display: flex;
    width: 1200px;
    // justify-content: center;
    // align-items: center;
    flex-direction: row;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 85px;
  }
  .main-left {
    flex: 1;
    margin-right: 10px;
    flex: 1;
    background-color: #ffffff;

    .left-top {
      padding-top: 30px;

      h2 {
        color: #00924c;
        position: relative;
        font-size: 16px;
        margin: 0px 0 0 30px;
      }
      h2::after {
        content: "";
        position: absolute;
        bottom: -21px;
        left: -13px;
        right: 8px;
        width: 90px;
        height: 2px;
        background: #00924c;
      }
    }
    .left-bottom {
      overflow: auto;
      width: 781px;
      height: 100%;
      margin: 20px 0;
      padding: 31px 0 0 31px;
      line-height: 2.5em;
      font-weight: 400;
    }
  }
  .main-right {
    width: 300px;
    background-color: #ffffff;
    min-height: 400px;

    .right-top {
      margin-bottom: 11px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .imgs {
        width: 110px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img {
          width: auto;
          height: 100%;
          text-align: center;
        }
      }

      h3 {
        text-align: center;
        font-size: 16px;
        margin-bottom: 14px;
        color: #333333;
      }
      .content {
        text-align: center;
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 20px;
        color: #666666;
        font-size: 14px;
        font-weight: normal;
        line-height: 28px;
        width: 252px;
        font-size: 14px;
        margin: auto;
        margin-bottom: 20px;
      }
      .connect {
        margin-top: 11px;
        margin: auto;
        width: 252px;
        font-weight: normal;
        p {
          line-height: 28px;
          font-size: 14px;
          color: #333333;
          span {
            color: #00924c;
          }
        }
      }
    }
  }
  .imgAd {
    width: 300px;
    height: 168px;
    img {
      width: 300px;
      height: 168px;
    }
  }
  /deep/ .el-dialog {
    .el-dialog_content {
      text-align: center;
      .tip {
        margin-bottom: 20px;
        h4 {
          text-align: center;
          font-size: 18px;
          small {
            font-size: 16px;
            color: #de4141;
          }
        }
      }
    }
    .el-form-item {
      margin-bottom: 22px;
    }
    .dialog-footer {
      text-align: center;
      .el-button {
        width: 170px;
        height: 40px;
        margin-right: 20px;
      }
      .el-button:nth-of-type(2) {
        border: 1px solid #00924c;
        color: #ffffff;
      }
    }
  }
}
</style>
